
import moment from 'moment';
import { defineComponent, reactive, ref, toRefs } from 'vue';
import HistoricoFaturamento from "./components/HistoricoFaturamento.vue";
import FaturamentoTotal from "./components/FaturamentoTotal.vue";
import ConversaoPacotes from "./components/ConversaoPacotes.vue";
import ConversaoPacotesTabela from "./components/ConversaoPacotesTabela.vue";
import TicketMedioTabela from "./components/TicketMedioTabela.vue";
import { getOnePageReportGrupo } from "@/services/OnePageReportGrupoService";
import useEmitter from '@/composables/Emmiter';
import useAlert from "@/composables/Alert";
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
	components: {
		HistoricoFaturamento,
		FaturamentoTotal,
		ConversaoPacotes,
		ConversaoPacotesTabela,
		TicketMedioTabela,
		ContentLoader
	},
	setup() {
		const dataHistoricoFaturamento = ref();
		const { showTimeAlert } = useAlert();

		const loading = reactive({
			mainLoading: true,
		});

		const grupo = reactive({
			nome: "",
			codGrupo: "",
			imagem: ""
		});

		const marca = reactive({
			nome: "",
			codMarca: "",
			imagem: ""
		});

		const infoDate = reactive({
			mesAnoFormatado: "",
			mes: "",
			ano: "",
			diaFim: "",
		});

		const infoData = reactive({
			faturamento: 0,
			percentualFaturamento: 0,
			vendasNaoRealizadas: 0,
			percentualVendasNaoRealizadas: 0,
			faturamentoDistribuidor: 0,
			percentualFaturamentoDistribuidor: 0,
			percentualFaturamentoColor: "",
			historicoFaturamento: [],
			graficoConversaoPacotes: [],
			graficoTicketMedio: [],
			faturamentoTotal: {},
			dadosPacotes: {}
		});

		const emitter = useEmitter();

		emitter.on("filtrar-one-page-report-marca", (filtroData) => {
			console.log(filtroData);
			const { ano, mes } = filtroData;

			infoDate.ano = ano;
			infoDate.mes = mes;


			grupo.nome = filtroData.marca.nomeGrupo;
			grupo.codGrupo = filtroData.marca.codGrupo;
			grupo.imagem = filtroData.marca.imagemGrupo;

			marca.nome = filtroData.marca.nomeMarca;
			marca.codMarca = filtroData.marca.codMarca;
			const auxImagem =  filtroData.marca.logomarca ? `./img_marca/${filtroData.marca.logomarca}` : filtroData.marca.imagemGrupo;
			marca.imagem = auxImagem;

			getData(grupo.codGrupo, marca.codMarca, ano, mes);
    	});

		async function getData(codGrupo, codMarca, ano, mes) {
			console.log("getData");
			loading.mainLoading = true;
			await getOnePageReportGrupo(codGrupo, ano, mes, codMarca).then((response) => {

				infoDate.diaFim = response.lancamento_ate;
				
				// Trata data e títulos
				const auxMesAnoTratado = moment(`${infoDate.mes}/${infoDate.ano}`, "MM/YYYY").locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de");
				infoDate.mesAnoFormatado = auxMesAnoTratado[0].toUpperCase() + auxMesAnoTratado.substring(1);
				// 

				infoData.faturamento = response["faturamento_concessionaria"].toLocaleString('pt-Br', {style: 'currency', currency: 'BRL', minimumFractionDigits: 2});
				infoData.percentualFaturamento = response["conversao"];

				if(response["conversao"] < 35){
					infoData.percentualFaturamentoColor = "var(--cor_indicadores_4)";
				} else if (response["conversao"] <= 50){
					infoData.percentualFaturamentoColor = "var(--cor_indicadores_3)";
				} else {
					infoData.percentualFaturamentoColor = "var(--cor_indicadores_1)";
				}

				infoData.vendasNaoRealizadas = response["vendasNaoRealizadas"];
				infoData.percentualVendasNaoRealizadas = 100 - response["conversao"];

				infoData.faturamentoDistribuidor = response["totalTicketMedioDistribuidor"];
				infoData.percentualFaturamentoDistribuidor = response["porcentagemDistribuidor"];

				infoData.historicoFaturamento = response["graficoAgregados"];
				infoData.faturamentoTotal = {
					ticketMes: response["dadosMes"],
					produtos: response["produtos"],
					revisoes: response["revisoes"],
					ticketmedioTotal: response["ticketmedioTotal"],
					ticketMedioDistribuidor: response["ticketMedioDistribuidor"],
				};

				let percentualPacotes:string | number = response["revisoes"] ? response["pacotes"] / response["revisoes"] : 0;
				percentualPacotes = percentualPacotes ? (percentualPacotes * 100).toFixed(2) : "0";

				infoData.dadosPacotes = {
					dados: response["dados_pacotes"],
					percentualPacotes: percentualPacotes,
					somaPacotes: response["pacotes"],
					revisoes: response["revisoes"]
				};

				infoData.graficoConversaoPacotes = response["graficoConversaoPacotes"].map((dado) => {
					let conversao: string | number = dado.nprodutos && dado.nrevisoes ? dado.nprodutos / dado.nrevisoes * 100 : 0;
					conversao = conversao >= 100 ? 100 : conversao;
					conversao = conversao.toFixed(2);

					let corPercentual: string = "var(--cor_indicadores_4)";
					
					if(Number(conversao) >= Number(dado.metaconversao) || (!dado.metaconversao)){
						corPercentual = "var(--cor_indicadores_1)";
					} else if(Number(conversao) >= (Number(dado.metaconversao) * 0.8) ){
						corPercentual = "var(--cor_indicadores_3)";
					}
					
					return {
						...dado,
						conversao,
						corPercentual
					}
				});

				infoData.graficoTicketMedio = response["graficoTicketMedio"].map((dado) => {
					const maxTicketMedio = dado.metaticketmedio ? dado.metaticketmedio * 1.25 : 0;

					let percentualTicketMedio: string | number = maxTicketMedio && dado.revisoes ? dado.revisoes / maxTicketMedio * 100 : 0;
					percentualTicketMedio = percentualTicketMedio >= 100 ? 100 : percentualTicketMedio;
					percentualTicketMedio = percentualTicketMedio.toFixed(2);

					let percentualMetaTicketMedio: string | number = maxTicketMedio && dado.metaticketmedio ? dado.metaticketmedio / maxTicketMedio * 100 : 0;
					percentualMetaTicketMedio = percentualMetaTicketMedio >= 100 ? 100 : percentualMetaTicketMedio;
					percentualMetaTicketMedio = percentualMetaTicketMedio.toFixed(2);

					let corPercentual: string = "var(--cor_indicadores_4)";
					
					if(Number(dado.revisoes) >= Number(dado.metaticketmedio) || (!dado.metaticketmedio)){
						corPercentual = "var(--cor_indicadores_1)";
					} else if(Number(dado.revisoes) >= (Number(dado.metaticketmedio) * 0.8) ){
						corPercentual = "var(--cor_indicadores_3)";
					}
					
					return {
						...dado,
						maxTicketMedio,
						percentualMetaTicketMedio,
						percentualTicketMedio,
						corPercentual
					}
				});
			}).catch(e => {
				showTimeAlert('Não foi possível completar a solicitação', "error");
			}).finally(() => {
				loading.mainLoading = false;
			})

		};

		return { ...toRefs(loading), grupo, marca, ...toRefs(infoDate), ...toRefs(infoData), dataHistoricoFaturamento }
	},
})
