
import moment from 'moment';
import { defineComponent, ref, watch } from 'vue'
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
    props: {
        historicoFaturamento: {
            type: Array
        },
        subtitle: {
            type: String
        },
        loading: {
            type: Boolean
        },
    },
    components: {
        ContentLoader
    },
	setup(props) {
		const chartOptions:any = ref(geraOptions([], []));
		const series:any = ref(geraSeries([], []));
		const subtitle:any = ref("");

        watch(() => props.historicoFaturamento, (value: any) => {
            if(value && value.length > 0){
                const auxPrimeiroMesAno =  moment(`${value[0].mes}/${value[0].ano}`, "MM/YYYY");
                const auxUltimoMesAno =  moment(`${value.at(-1).mes}/${value.at(-1).ano}`, "MM/YYYY");

                let auxPeriodoHistorico = `${auxPrimeiroMesAno.startOf("month").format('DD/MM/YYYY')} à `;
                if(moment().isSame(auxUltimoMesAno, "month")){
                    auxPeriodoHistorico += `${moment().format('DD/MM/YYYY')}`;
                } else {
                    auxPeriodoHistorico += `${auxUltimoMesAno.endOf('month').format('DD/MM/YYYY')}`;
                }
                
                subtitle.value = auxPeriodoHistorico;


                const labels = value.map(dadoMes => {
                    const mesAbreviado = dadoMes.nomemes.substring(0, 3);

                    return [mesAbreviado, dadoMes.ano];
                });

                const labelsFull = value.map(dadoMes => {
                    const auxNomeMes = dadoMes.nomemes[0].toUpperCase() + dadoMes.nomemes.substring(1);

                    return `${auxNomeMes} de ${dadoMes.ano}`;
                });

                chartOptions.value = geraOptions(labels, labelsFull);

                const arrayFaturamento = value.map(dadoMes => dadoMes.faturamento);
                const arrayRevisoes = value.map(dadoMes => dadoMes.revisoes);

                series.value = geraSeries(arrayFaturamento, arrayRevisoes);
                resizeWithTime();
            }
        }, { deep: true });

        function geraSeries(arrayFaturamento, arrayRevisoes){
            const auxSeries = [
				{
					name: 'Faturamento',
					type: 'area',
					data: arrayFaturamento
				}, 
				{
					name: 'Revisões',
					type: 'line',
					data: arrayRevisoes
				}
			];

            return auxSeries;
        };

        function resizeWithTime(time = 100){
            setTimeout(() => window.dispatchEvent(new Event('resize')), time);
        };

        function geraOptions(labels, labelsFull){
            const options = {
                chart: {
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                },
                grid: {
                    show: true,
                    padding: {
                        left: 30,
                        right: 50 
                    }
                },
                colors: ['var(--cor_indicadores_2_opct)', 'var(--cor_indicadores_4)'],
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [0],
                    distributed: true,
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontWeight: 'bold',
                        colors: ["var(--cor_indicadores_2)"]
                    },
                    formatter: (val) => {
                        const auxVal = val || val == 0 ? val.toLocaleString('pt-Br', {style: 'currency', currency: 'BRL', maximumFractionDigits: 0}) : 0;
                        return auxVal;
                    },
                    background: {
                        enabled: false,
                    },
                },
                stroke: {
                    curve: 'smooth',
                    width: [0, 5]
                },
                fill: {
                    type: 'solid',
                    opacity: [0.35, 1],
                },
                yaxis: [
                    {
                        labels: {
                            show: false,
                        },
                    },
                    {
                        labels: {
                            show: false,
                        },
                    },
                ],
                labels,
                xaxis: {
                    labels: {
                        show: true,
                        style: {
                            fontSize: "12px",
                        },
                        formatter: (value)=> {
                            return value
                        }
                    },
                },
                legend: {
                    position: "top",
                    horizontalAlign: 'right',
                },
                tooltip: {
                    intersect: false,
                    shared: true,
                    style: {
                        fontSize: "12px",
                    },
                    x: {
                        formatter: (val) => {
                            return labelsFull[val - 1];
                        },
                    },
                    y: [
                        {
                            formatter: (val) => {
                                return val.toLocaleString('pt-Br', {style: 'currency', currency: 'BRL', maximumFractionDigits: 0});
                            },
                        },
                        {
                            formatter: (val) => {
                                return val;
                            },
                        },
                    ],
                    marker: {
                        show: true,
                    },
                }
            }

            return options;
        };

		return { chartOptions, series, subtitle }
	},
})
